<template>
  <v-card>
    <v-card-title v-if="activityCount.length > 0">{{ activityCount[0].projectName }}</v-card-title>

    <v-card-text>
      <v-row>
        <v-col md="6">
          <v-select multiple :items="status" label="Status" v-model="statusFilter"></v-select>
        </v-col>

        <v-col md="6">
          <v-btn @click="getActivityStatus()" color="primary" class="mr-2">Apply Filter</v-btn>
          <v-btn @click="getActivityStatus(true)" color="primary">Clear Filter</v-btn>
        </v-col>
      </v-row>

      <table class="table table-bordered">
        <thead class="thead-dark">
          <tr>
            <th>Activity Name</th>
            <th>Activity Status</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(count, index) in activityCount" :key="count._id">
            <td>{{activityCount[index].activityName}}</td>
            <td>{{ activityMapper(activityCount[index].status)}}</td>
            <td>{{activityCount[index].count}}</td>
          </tr>
        </tbody>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
import RestResource from "../../services/dataServiceMobile.js";
const service = new RestResource();

export default {
  data() {
    return {
      activityCount: [],
      statusFilter: null,
      status: [
        {
          text: "Active",
          value: "A"
        },
        {
          text: "Completed",
          value: "C"
        },
        {
          text: "Terminated",
          value: "T"
        }
      ]
    };
  },

  mounted() {
    this.getActivityStatus();
  },

  methods: {
    activityMapper(activity) {
      if (activity == "A") return "Active";
      else if (activity == "C") return "Completed";
      else if (activity == "T") return "Terminated";
      else return undefined;
    },

    getActivityStatus(type) {
      if (type == true) {
        this.statusFilter = null
      }

      this.$setLoader();

      service.getActivityStatus({
        projectId: this.$route.params.id,
        status: this.statusFilter ? this.statusFilter : undefined
      }).then(res => {
        this.activityCount = res.data;
        this.$disableLoader();
      });
    },
  }
};
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/bootstrap.scss";
</style>